.control {
  @include font-300-14;

  line-height: rem(40px);
  height: $control-height;
  border-radius: $border-radius-control;
  background-color: $white-1;
  padding: $sp-8 $sp-12;
  color: $color-text-control;
  caret-color: $color-text-control;
  border: 1px solid $color-border-control-color;
  font-family: $base-font-family;

  &::placeholder {
    color: $color-text-control-placeholder;
    padding-top: 1px; // Workaround to avoid fonts cuts off
  }

  &:read-only {
    pointer-events: none;
  }

  + .control-icon {
    cursor: pointer;
    position: absolute;
    right: $sp-16;
    top: calc(50%);
    transform: translateY(-50%);
    color: $color-text-control;
  }

  &_with-icon {
    padding-right: calc($sp-16 + $icon-size-control);
  }

  &_invalid {
    @include invalid-control;
  }

  &_error-message {
    @include font-400-14;

    color: $color-state-danger;
    padding-top: $sp-6;
    padding-left: $sp-16;
  }
}
