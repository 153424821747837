$white: #fff;
$white-1: #f6f1ff;
$white-2: #ececec;
$white-3: #ffffffb8;
$white-4: #ffffffa3;
$black: #0000;
$black-2: #ffffff0a;
$black-3: #ffffff0f;
$black-4: #292929;
$black-5: #222;
$black-6: #000000a3;
$black-7: #1b1b1d;
$black-8: #ffffff05;
$black-9: #00000005;
$silver: #ffffff3d;
$lavender: #c1b9e2;
$periwinkle: #d8d3ed;
$gainsboro: #d9d9d9;
$dove: #e8e5f4;
$purple-1200: #6351b7;
$purple-1100: #996aff;
$purple-1000: #090616b3;
$purple-900: #090616;
$purple-800: #6351b733;
$purple-700: #b3a3ff;
$purple-600: #33266e;
$purple-500: #0e0a21;
$purple-400: #272144;
$purple-300: #3b298f;
$purple-250: #322c4e;
$purple-200: #4f3da3;
$purple-120: #6351b7b3;
$purple-100: #6351b7;
$purple-50: #8274c5;
$purple-40: #8e83c4;
$purple-30: #a39bc4;
$blue: #4095e9;
$blue-1: #a0fefa;
$blue-2: #6fa4fa;
$orange-200: #ff6363;
$grey: #cbcbcb;
$pinkish-red: #f2759cb3;
$neutral-800: #1b1b1d;
$neutral-400: #6a6a6b;
$neutral-300: #a6a6a6;

// General colors
$brand-primary: #ff3f3f;
$brand-secondary: #30d46c;

// General state
$color-state-success: $brand-secondary;
$color-state-danger: $orange-200;
$color-state-error: $brand-primary;
$color-state-info: $purple-100;

// Header
$header-line-background: linear-gradient(90deg, #f2759c 25%, $purple-700 60%, $purple-120 90%);
$header-line-shadow:
  0 33px 5rem #fce48f99,
  0 1rem 2.5rem #bb8ffca3,
  0 0.625rem 1.5rem #fce48f7a,
  0 0.75rem 1.5rem #bb8ffc8f,
  0 0.2625rem 0.6563rem #fce48f4d,
  0 0.1563rem 0.3906rem #bb8ffc40,
  0 0.0938rem 0.225rem #fce48f33,
  0 0.0413rem 0.1rem #bb8ffc24;

// Semantic colors, could be extended
$color-bg-primary: $purple-900;
$color-bg-stepper: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, #b3a3ff 50%, rgb(255 255 255 / 0%) 100%);
$color-text-primary: $white-1;
$color-bg-icon: $gainsboro;
$color-link: $blue-2;

// Buttons
$color-bg-button: $white;
$color-bg-secondary-button: $pinkish-red;
$color-bg-primary-button: $purple-1200;
$color-text-button: $white-1;
$color-bg-primary-button-shadow:
  0 0 6rem $purple-800,
  0 0 3.5rem #6351b780,
  0 0 1rem #6351b766;
$color-bg-secondary-button-shadow:
  0 0 6rem #f2759c33,
  0 0 3.5rem #f2759c80,
  0 0 1rem #f2759c66;

// Checkbox
$color-bg-checkbox: $white-1;
$color-border-checkbox: $white-1;
$color-icon-checkbox: $purple-100;

// Controls
$color-text-control-placeholder: rgba($purple-100, 0.4);
$color-text-control: $purple-100;
$color-border-control-color: $periwinkle;

// Select
$color-text-select-placeholder: $color-text-control-placeholder;
$color-text-select: $purple-100;
$color-bg-item-hover: linear-gradient(90deg, $purple-30 0%, $purple-40 48%, $purple-50 88%);

// Horizontal line
$color-bg-horizontal-line: $color-border-control-color;

// Gradient
$main-gradient: linear-gradient(90deg, #f2759c 25%, $purple-700 60%, $purple-100);
$border-gradient: conic-gradient(
  from 180deg at 50% 50%,
  #b3a3ff -16.75deg,
  rgb(179 163 255 / 45%) 7.58deg,
  #b3a3ff 116.08deg,
  rgb(179 163 255 / 5%) 178.34deg,
  #b3a3ff 251.48deg,
  #b3a3ff 343.25deg,
  rgb(179 163 255 / 45%) 367.58deg
);

// Schemas
$schema-background-hover: linear-gradient(180deg, rgb(99 81 183 / 0%) 0%, $purple-100 100%);
$schema-shadow: 0 -10px 60px 0 #81a4ff80 inset;
$first-schema-bg: linear-gradient(
  90deg,
  rgb(130 116 197 / 15%) 12%,
  rgb(142 131 196 / 15%) 52%,
  rgb(163 155 196 / 15%) 100%
);

// Toast
$color-bg-toast: $purple-250;
$color-toast-text: $dove;
$color-toast-title: $white;
$shadow-toast: 0 8px 12px 0 rgb(0 0 0 / 6%);

// Auth
$color-auth: $white;
$color-auth-check: $purple-300;

// Accordion
$color-bg-accordion: $purple-900;
$color-border-accordion: $black-3;

// Hilighted
$color-bg-hilighted: linear-gradient(120deg, #f2759c 25%, #b3a3ff 60%, $purple-100 90%);
$color-bg-hilighted-2: linear-gradient(90deg, #8274c5 0%, #8e83c4 50%, #a39bc4 100%);

// Main
$color-bg-main-section: radial-gradient(circle at 50% 100%, #6f44fe1a 50%, #6f44fe00);

// Backdrop
$color-bg-backdrop: rgb(13 16 22 / 70%);

// General
$color-circeled-check: $purple-300;

// Gradient
$color-bg-gradient-1: linear-gradient(90deg, #a39bc4 0%, #8e83c4 48%, #8274c5 88%);

// Not found
$color-bg-not-found-description: linear-gradient(
  90deg,
  rgb(163 155 196 / 50%) 0%,
  rgb(142 131 196 / 50%) 48%,
  rgb(130 116 197 / 50%) 88%
);

// Header
$header-line-background: linear-gradient(90deg, #f2759c 25%, $purple-700 60%, $purple-100 90%);
$header-line-shadow:
  0 33px 5rem #fce48f99,
  0 1rem 2.5rem #bb8ffca3,
  0 0.625rem 1.5rem #fce48f7a,
  0 0.75rem 1.5rem #bb8ffc8f,
  0 0.2625rem 0.6563rem #fce48f4d,
  0 0.1563rem 0.3906rem #bb8ffc40,
  0 0.0938rem 0.225rem #fce48f33,
  0 0.0413rem 0.1rem #bb8ffc24;

// Language Selector
$language-selector-dropdown-shadow:
  0 12px 1rem -0.25rem #00000014,
  0 0.25rem 0.375rem -0.125rem #10182808;
