@use "sass:math";

$font-family-space-grotesk: "Spacegrotesk", sans-serif;
$base-font-family: $font-family-space-grotesk;
$base-font-size: 16px !default;

// Font weight
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

// -------------------------------------
// Function for converting to rem
// -------------------------------------
// usage:
// @import 'typography'
// top: rem(100px)
// -------------------------------------
@function rem($px) {
  @return math.div($px, $base-font-size) * 1rem;
}

// Added basic font's mixins, could be extended

@mixin font-300-12 {
  font-size: rem(12px);
  line-height: 1.7;
  font-weight: $font-weight-300;
}

@mixin font-300-14 {
  font-size: rem(14px);
  line-height: 1.4;
  font-weight: $font-weight-300;
}

@mixin font-300-16 {
  font-size: rem(16px);
  line-height: rem(18px);
  font-weight: $font-weight-300;
}

@mixin font-300-18 {
  font-size: rem(18px);
  line-height: rem(28.8px);
  font-weight: $font-weight-300;
}

@mixin font-400-12 {
  font-size: rem(12px);
  line-height: rem(15px);
  font-weight: $font-weight-400;
}

@mixin font-400-14 {
  font-size: rem(14px);
  line-height: rem(17px);
  font-weight: $font-weight-400;
}

@mixin font-400-15 {
  font-size: rem(15px);
  line-height: rem(28.8px);
  font-weight: $font-weight-400;
}

@mixin font-400-16 {
  font-size: rem(16px);
  line-height: rem(19px);
}

@mixin font-400-17 {
  font-size: rem(17px);
  line-height: rem(20px);
  font-weight: $font-weight-400;
}

@mixin font-400-18 {
  font-size: rem(18px);
  line-height: rem(24px);
  font-weight: $font-weight-400;
}

@mixin font-400-20 {
  font-size: rem(20px);
  line-height: rem(24px);
  font-weight: $font-weight-400;
}

@mixin font-400-24 {
  font-size: rem(24px);
  line-height: rem(28px);
  font-weight: $font-weight-400;
}

@mixin font-400-36 {
  font-size: rem(36px);
  line-height: rem(46px);
  font-weight: $font-weight-400;
}

@mixin font-500-10 {
  font-size: rem(10px);
  line-height: rem(13px);
  font-weight: $font-weight-500;
}

@mixin font-500-12 {
  font-size: rem(12px);
  line-height: rem(15px);
  font-weight: $font-weight-500;
}

@mixin font-500-14 {
  font-size: rem(14px);
  line-height: rem(24px);
  font-weight: $font-weight-500;
}

@mixin font-500-16 {
  font-size: rem(16px);
  line-height: rem(19px);
  font-weight: $font-weight-500;
}

@mixin font-500-17 {
  font-size: rem(17px);
  line-height: rem(28px);
  font-weight: $font-weight-500;
}

@mixin font-500-18 {
  font-size: rem(18px);
  line-height: rem(23px);
  font-weight: $font-weight-500;
  letter-spacing: 0.0625rem;
}

@mixin font-500-19 {
  font-size: rem(19px);
  line-height: 1.2;
  font-weight: $font-weight-500;
  letter-spacing: -0.0106rem;
}

@mixin font-500-20 {
  font-size: rem(20px);
  line-height: 1.4;
  font-weight: $font-weight-500;
}

@mixin font-500-21 {
  font-size: rem(21px);
  line-height: rem(25px);
  font-weight: $font-weight-500;
}

@mixin font-500-24 {
  font-size: rem(24px);
  font-weight: $font-weight-500;
}

@mixin font-500-27 {
  font-size: rem(27px);
  line-height: rem(30px);
  font-weight: $font-weight-500;
}

@mixin font-500-30 {
  font-size: rem(30px);
  line-height: rem(45px);
  font-weight: $font-weight-500;
}

@mixin font-500-32 {
  font-size: rem(32px);
  line-height: rem(40px);
  font-weight: $font-weight-500;
}

@mixin font-500-36 {
  font-size: rem(36px);
  line-height: rem(45px);
  font-weight: $font-weight-500;
}

@mixin font-500-35 {
  font-size: rem(35px);
  line-height: rem(43px);
  font-weight: $font-weight-500;
}

@mixin font-500-38 {
  font-size: rem(38px);
  line-height: rem(46px);
  font-weight: $font-weight-500;
}

@mixin font-500-40 {
  font-size: rem(40px);
  line-height: rem(45px);
  font-weight: $font-weight-500;
}

@mixin font-500-52 {
  font-size: rem(52px);
  line-height: rem(60px);
  font-weight: $font-weight-500;
}

@mixin font-600-14 {
  font-size: rem(14px);
  line-height: rem(17px);
  font-weight: $font-weight-600;
}

@mixin font-600-20 {
  font-size: rem(20px);
  line-height: rem(24px);
  font-weight: $font-weight-600;
}

@mixin font-600-9 {
  font-size: rem(9px);
  line-height: rem(11px);
  font-weight: $font-weight-600;
}

@mixin font-600-16 {
  font-size: rem(16px);
  line-height: rem(20px);
  font-weight: $font-weight-600;
}

@mixin font-600-19 {
  font-size: rem(19px);
  line-height: rem(21px);
  font-weight: $font-weight-600;
}

@mixin font-600-21 {
  font-size: rem(21px);
  line-height: rem(26px);
  font-weight: $font-weight-600;
}

@mixin font-600-23 {
  font-size: rem(23px);
  line-height: rem(28px);
  font-weight: $font-weight-600;
}

@mixin font-600-40 {
  font-size: rem(40px);
  line-height: rem(50px);
  font-weight: $font-weight-600;
}

@mixin font-600-52 {
  font-size: rem(52px);
  line-height: rem(60px);
  font-weight: $font-weight-600;
}

@mixin font-700-10 {
  font-size: rem(10px);
  line-height: rem(11px);
  font-weight: $font-weight-700;
}

@mixin font-700-12 {
  font-size: rem(12px);
  line-height: rem(13px);
  font-weight: $font-weight-700;
}

@mixin font-700-14 {
  font-size: rem(14px);
  line-height: rem(14px);
  font-weight: $font-weight-700;
}

@mixin font-700-16 {
  font-size: rem(16px);
  line-height: rem(16px);
  font-weight: $font-weight-700;
}

@mixin font-700-18 {
  font-size: rem(18px);
  line-height: rem(22.97px);
  font-weight: $font-weight-700;
}

@mixin font-700-22 {
  font-size: rem(22px);
  line-height: rem(27px);
  font-weight: $font-weight-700;
}

@mixin font-700-28 {
  font-size: rem(28px);
  line-height: rem(31px);
  font-weight: $font-weight-700;
}

@mixin font-700-36 {
  font-size: rem(36px);
  line-height: rem(43px);
  font-weight: $font-weight-700;
}

@mixin font-700-39 {
  font-size: rem(39px);
  line-height: rem(43px);
  font-weight: $font-weight-700;
}

@mixin font-700-64 {
  font-size: rem(64px);
  line-height: rem(64px);
  font-weight: $font-weight-700;
}

@mixin font-800-9 {
  font-size: rem(9px);
  line-height: rem(11px);
  font-weight: $font-weight-800;
}

@mixin font-800-14 {
  font-size: rem(14px);
  line-height: rem(16px);
  font-weight: $font-weight-800;
}

@mixin font-800-16 {
  font-size: rem(16px);
  line-height: rem(20px);
  font-weight: $font-weight-800;
}

@mixin font-800-20 {
  font-size: rem(20px);
  line-height: rem(23px);
  font-weight: $font-weight-800;
}

@mixin font-800-22 {
  font-size: rem(22px);
  line-height: rem(27px);
  font-weight: $font-weight-800;
}

@mixin font-800-28 {
  font-size: rem(28px);
  line-height: rem(34px);
  font-weight: $font-weight-800;
}

@mixin font-800-30 {
  font-size: rem(30px);
  line-height: rem(34px);
  font-weight: $font-weight-800;
}

@mixin font-800-35 {
  font-size: rem(35px);
  line-height: rem(38px);
  font-weight: $font-weight-800;
}

@mixin font-800-36 {
  font-size: rem(36px);
  line-height: rem(43px);
  font-weight: $font-weight-800;
}

@mixin font-800-38 {
  font-size: rem(38px);
  line-height: rem(41px);
  font-weight: $font-weight-800;
}

@mixin font-800-40 {
  font-size: rem(40px);
  line-height: rem(48px);
  font-weight: $font-weight-800;
}

@mixin font-800-48 {
  font-size: rem(48px);
  line-height: rem(52px);
  font-weight: $font-weight-800;
}

@mixin font-800-70 {
  font-size: rem(70px);
  line-height: rem(85px);
  font-weight: $font-weight-800;
}

@mixin font-800-82 {
  font-size: rem(82px);
  line-height: rem(90px);
  font-weight: $font-weight-800;
}

@mixin font-800-100 {
  font-size: rem(100px);
  line-height: rem(115px);
  font-weight: $font-weight-800;
}

@mixin font-800-200 {
  font-size: rem(200px);
  line-height: rem(255.2px);
  font-weight: $font-weight-800;
}

@mixin font-800-220 {
  font-size: rem(220px);
  line-height: rem(250px);
  font-weight: $font-weight-800;
}
