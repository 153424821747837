.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

/* Flex direction */
.flex-row {
  flex-direction: row;
} /* Default */
.flex-column {
  flex-direction: column;
} /* Default */

/* Flex wrap */
.flex-wrap {
  flex-wrap: wrap;
}

/* Justify content */
.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

/* Align items */
.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

/* Align self */
.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}
