@mixin button-reset($width: true) {
  @include _reset;
  @include _font-smoothing;

  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  letter-spacing: 0;
  text-decoration: none;

  @if $width == true {
    width: 100%;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin _reset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;

  & > *,
  & > *::before,
  & > *::after {
    box-sizing: inherit;
  }
}

// Only applied to bold weight text
@mixin _font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin background-gradient($gradient) {
  background: $gradient;
}

@mixin invalid-control {
  color: $color-state-error;
  caret-color: $color-state-error;
  border: $border-radius-0 solid $color-state-danger;

  + .control-icon {
    color: $color-state-danger;
  }

  &::placeholder {
    color: $color-state-error;
  }
}

@mixin link-underline {
  cursor: pointer;
  text-decoration: underline;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin text-gradient($backgroundColor) {
  background: $backgroundColor;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin border-gradient {
  border: 1px solid transparent;
  border-image-source: $border-gradient;
  border-image-slice: 1;
}

@mixin section-padding {
  padding: 0 3%;
  padding-bottom: rem(1px);
  position: relative;
}

@mixin section-inner-intends {
  z-index: 1;
  width: 100%;
  max-width: $section-max-width;
  margin-left: auto;
  margin-right: auto;
  padding: $sp-96 $sp-48;
  position: relative;

  &.privacy {
    padding-top: 3rem;
  }

  @include breakpoint-max(l) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include breakpoint-max(md) {
    padding: $sp-64 $sp-24;
  }

  @include breakpoint-max(ml) {
    padding: $sp-64 $sp-8;
  }
}

@mixin section-inner-container {
  width: 100%;
  max-width: rem(960px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@mixin title-large {
  grid-gap: $sp-12 $sp-12;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 46.5rem;
  margin-bottom: $sp-40;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  &.privacy {
    margin-bottom: 0.5rem;
  }
}

@mixin header-2 {
  @include font-500-32;

  color: $white-1;
  letter-spacing: -0.19px;

  @include breakpoint-max(md) {
    font-size: 1.688rem;
  }
}

@mixin header-5 {
  @include font-500-24;

  color: $white-1;
}

@mixin paragraph-regular {
  @include font-400-14;

  color: $white-3;
  line-height: 1.7;

  @include breakpoint-max(md) {
    @include font-400-12;

    line-height: 1.5;
  }

  font-weight: normal;
}

@mixin gradient-span {
  background-image: linear-gradient(120deg, #f2759c 25%, $purple-700 60%, $purple-100 90%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
