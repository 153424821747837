// NG-SELECT OVERRIDES

.ng-select.ui-select {
  &.ng-select-single {
    .ng-select-container {
      height: $select-height;

      .ng-value-container {
        .ng-placeholder {
          position: relative;
          top: $sp-2;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: $select-height;

      .ng-value-container {
        flex-wrap: nowrap;
        width: 80%;
        overflow: hidden;

        .ng-value {
          margin-bottom: 0;
          margin-right: 0;
          background-color: transparent;
          border: none;

          .ng-value-label {
            padding-left: 0;

            &::after {
              content: ",";
            }
          }

          .ng-value-icon {
            display: none;
          }
        }

        .ng-input {
          display: none;
        }

        .ng-placeholder {
          padding: 0;
          top: $sp-8;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          .ng-option-label {
            position: relative;
            padding-left: $sp-24;

            &::after {
              position: absolute;
              left: 0;
              top: -2px;
              content: "";
              width: rem(12px);
              height: rem(12px);
              border: 2px solid $purple-100;
              border-radius: $border-radius-1;
              background-color: transparent;
            }
          }

          &.ng-option-selected {
            .ng-option-label {
              position: relative;
              font-weight: $font-weight-300;

              &::after {
                background-color: $purple-100;
              }

              &::before {
                position: absolute;
                display: block;
                content: "";
                top: 3px;
                left: rem(3px);
                width: rem(11px);
                height: rem(8px);
                mask: url("/assets/icons/check.svg") no-repeat 100% 100%;
                mask-size: cover;
                background-color: $white-1;
                z-index: 1;
              }
            }
          }
        }

        .ng-option:last-child::after {
          display: none;
        }
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    & > .ng-select-container {
      border: none;
      box-shadow: none;
    }
  }

  .ng-select-container {
    border: 1px solid $color-border-control-color;
    border-radius: $border-radius-control;
    min-height: $select-height;
    background-color: $white-1;

    .ng-arrow-wrapper {
      $icon-path: "/assets/icons/triangle-down.svg";

      width: $select-arrow-size;
      height: $select-arrow-size;
      margin-right: $sp-16;
      mask: url($icon-path) no-repeat 100% 100%;
      mask-size: cover;
      background-color: $purple-100;

      .ng-arrow {
        border-color: transparent;
        border-style: none;
        border-width: 0;
      }
    }

    .ng-value-container {
      padding: 0 $sp-12;

      .ng-input {
        padding-left: $sp-32;

        & > input {
          @include font-300-14;
        }
      }

      .ng-placeholder {
        @include font-300-14;

        line-height: 1.2;
        overflow: hidden;
        text-wrap: nowrap;
        width: 75%;
        text-overflow: ellipsis;
        color: $color-text-select-placeholder;
      }

      .ng-value {
        &-label {
          @include font-300-14;

          line-height: 2;
          color: $color-text-select;
          position: relative;
          top: $sp-2;
        }
      }
    }
  }

  &.ng-select-opened {
    &.ng-select-bottom {
      & > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ng-value-container {
          .ng-input {
            top: $sp-32;
          }
        }
      }
    }
  }

  &.ng-select-opened.ng-select-top > .ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;

    .ng-value-container {
      .ng-input {
        top: $sp-32;
      }
    }
  }

  .ng-dropdown-panel {
    border: 1px solid $color-border-control-color;
    box-shadow: none;
    overflow: hidden;

    .ng-dropdown-panel-items {
      @include breakpoint(md) {
        max-height: var(--select-dropdown-max-height);
      }

      .ng-option {
        background-color: $white-1;
        padding: $sp-12 $sp-16;
        color: $color-text-select;

        @include font-300-14;

        &:hover {
          color: $white-1;
          font-weight: $font-weight-300;
          background: $color-bg-item-hover;
        }

        &.ng-option-selected {
          @include font-300-14;

          &:hover {
            .ng-option-label {
              &::after {
                background-color: $white-1;
                border-color: $white-1;
              }

              &::before {
                background-color: $purple-100;
              }
            }
          }
        }
      }
    }
  }

  &.invalid {
    .ng-select-container {
      border: $border-radius-0 solid $color-state-danger;

      .ng-arrow-wrapper {
        background-color: $color-state-danger;
      }

      .ng-placeholder {
        color: $color-state-danger;
      }
    }

    .ng-dropdown-panel.ng-select-bottom,
    .ng-dropdown-panel.ng-select-top {
      border: $border-radius-0 solid $color-state-danger;
    }

    &.ng-select-opened {
      .ng-select-container {
        border-bottom: none;
      }

      .ng-dropdown-panel.ng-select-bottom {
        border-top: none;
      }

      .ng-dropdown-panel.ng-select-top {
        border-bottom: none;
      }

      &.ng-select-top {
        & > .ng-select-container {
          border-top: none;
          border-bottom: $border-radius-0 solid $color-state-danger;
        }
      }
    }
  }
}

.ng-select.sm {
  &.ui-select__language-selector {
    &.ng-select-single {
      .ng-select-container {
        padding: 0 1.5rem 0 0.8rem;
      }
    }

    & .ng-dropdown-panel {
      padding: $sp-16;
      border: 0.0625rem solid #292929;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      max-height: $select-sm-height;

      @include breakpoint-max(xl) {
        height: $select-mobile-sm-height;
      }

      .ng-value-container {
        padding: $sp-12 $sp-8 0 $sp-8;
      }

      .ng-arrow-wrapper {
        $icon-path: "/assets/icons/down-lang-arrow.svg";

        mask: url($icon-path) no-repeat 50% 50%;
        width: rem(14px);
        height: rem(14px);
      }
    }
  }

  .ng-select-container {
    display: flex;
    flex-direction: column;
    max-height: $select-sm-height;
    border: none;

    @include breakpoint-max(xl) {
      max-height: $select-mobile-sm-height;
    }

    .ng-arrow-wrapper {
      margin-right: 0;
    }

    .ng-value-container {
      & [role="combobox"] {
        display: none;
      }

      .ng-value {
        &-label {
          @include font-500-18;
        }
      }
    }
  }

  &.ng-select-opened {
    &.ng-select-bottom {
      & > .ng-select-container {
        min-height: 0;
      }
    }
  }

  .ng-dropdown-panel {
    padding: 0;
    border: none;

    .ng-dropdown-panel-items {
      .ng-option {
        margin: 0;
        padding: $sp-10 0 $sp-6;
        color: $color-text-select;
        display: flex;
        justify-content: center;

        @include font-500-18;

        &.ng-option-marked,
        &.ng-option-selected {
          .ng-option-label {
            @include font-500-18;
          }
        }
      }
    }
  }
}

.ng-select.ui-select__language-selector {
  &.ng-select-single {
    .ng-select-container {
      flex-direction: row;
      gap: 3px;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1.5rem;
      min-width: $language-selector-width;
      min-height: $language-selector-height;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: $purple-800;

        .ng-value-container .ng-value .ng-value-label {
          color: $white-2;
        }
      }

      .ng-value-container {
        padding: 0;
        padding-left: 0;
        min-width: fit-content;
        flex: 0;

        .ng-input {
          display: none;
        }

        .ng-value {
          .ng-value-label {
            @include font-500-14;

            letter-spacing: 0;
            color: $periwinkle;
          }
        }
      }

      .ng-arrow-wrapper {
        $icon-path: "/assets/icons/arrow-down.svg";

        padding: 0;
        background-color: $periwinkle;
        mask: url($icon-path) no-repeat 100% 100%;
      }
    }
  }

  & .ng-dropdown-panel {
    background: $purple-1000;
    box-shadow: $language-selector-dropdown-shadow;
    border: none;
    border-radius: $sp-12;
    height: $language-selector-dropdown-height;
    padding: $sp-14 0 $sp-14 $sp-22;
    margin-bottom: 1.5rem;

    & .ng-dropdown-panel-items {
      & .ng-option {
        @include font-500-14;

        letter-spacing: 0;
        background: none;
        padding: 0;
        justify-content: flex-start;
        color: $periwinkle;

        &.ng-option-marked,
        &.ng-option-selected {
          .ng-option-label {
            @include font-500-14;

            letter-spacing: 0;
          }
        }

        &-selected {
          color: $purple-700;
        }

        &-label {
          @include font-500-14;

          letter-spacing: 0;
        }

        &:not(:nth-child(1)) {
          margin-top: $sp-16;
        }

        &:hover {
          background: none;
          color: $purple-100;
        }
      }
    }
  }

  &.ng-select-opened {
    &.ng-select-bottom {
      & > .ng-select-container {
        min-height: $language-selector-height;
      }
    }
  }
}

div.cdk-overlay-container > div.cdk-global-overlay-wrapper > div[id*="cdk-overlay-"].cdk-overlay-pane {
  display: flex !important;
}

.rp-home-overlay-panel {
  width: 100vw;
  height: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateY(0);
  }
}

/* Hide Google Recaptcha */
.grecaptcha-badge {
  display: none !important;
}
