@font-face {
  font-family: Spacegrotesk;
  src: url("./fonts/SpaceGrotesk-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Spacegrotesk;
  src: url("./fonts/SpaceGrotesk-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Spacegrotesk;
  src: url("./fonts/SpaceGrotesk-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Spacegrotesk;
  src: url("./fonts/SpaceGrotesk-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
