@import "global";
@import "@angular/cdk/overlay-prebuilt.css";

:root {
  --select-dropdown-max-height: 252px;
}

html {
  font-size: calc(0.625rem + 0.417vw);
}

@media screen and (width <= 1920px) {
  html {
    font-size: calc(0.625rem + 0.4167vw);
  }
}

@media screen and (width <= 1440px) {
  html {
    font-size: calc(0.8127rem + 0.2081vw);
  }
}

@media screen and (width <= 479px) {
  html {
    font-size: calc(0.7495rem + 0.8368vw);
  }
}

::-webkit-scrollbar {
  width: 6px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: rgb(9 6 22);
  border-radius: 0;
}

/* Scrollbar thumb with gradient and rounded corners */
::-webkit-scrollbar-thumb {
  background: linear-gradient(190deg, #f2759c 35.67%, #b3a3ff 65.49%, #6351b7 91.48%);
  border-radius: 3px;
}

body {
  font-family: $base-font-family;
  background: $color-bg-primary;
  display: flex;
  justify-content: center;
  overflow: hidden scroll;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $neutral-400;
  position: relative;
}

h1 {
  color: $white-1;
  letter-spacing: -0.0138rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: rem(60px);
  font-weight: 500;
  line-height: 1.2;

  @include breakpoint-max(md) {
    font-size: rem(40px);
  }
}

h2 {
  color: $white-1;
  letter-spacing: -0.0119rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;
}

h3 {
  color: $white-1;
  letter-spacing: -0.0106rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h5 {
  color: $white-1;
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

ul.privacy {
  display: block;
  list-style-type: disc;
  margin-block: 1em;
  margin-inline: 0;
  padding-inline-start: 40px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  font-size: 1rem;

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
}

.paragraph-regular {
  margin-bottom: 0;
  font-size: 0.875rem !important;
  line-height: 1.7 !important;

  @include breakpoint-max(ml) {
    font-size: 0.75rem !important;
    line-height: 1.5 !important;
  }

  &.privacy {
    color: $periwinkle;
    margin-top: 0;
    margin-bottom: 0.5rem;

    &._2nd {
      padding-left: 5rem;
    }
  }
}

.highlighted {
  @include text-gradient($color-bg-hilighted);

  &_primary {
    @include text-gradient($color-bg-hilighted-2);
  }
}

.page-title,
.page-sub-title {
  color: $color-text-primary;
  text-align: center;
}

.page-title {
  @include font-500-36;

  @include breakpoint-max(md) {
    @include font-500-27;
  }
}

.page-sub-title {
  color: $periwinkle;

  @include font-300-14;
}

.link {
  color: $color-link;

  @include link-underline;
  @include font-300-18;

  @include breakpoint-max(md) {
    @include font-400-16;
  }
}

.gradient-span {
  background-image: linear-gradient(120deg, #f2759c 25%, $purple-700 60%, $purple-100 90%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.headline-regular {
  @include font-400-18;

  color: $neutral-400;

  @include breakpoint-max(md) {
    @include font-400-16;
  }

  line-height: 1.35 !important;
}

.heading-2 {
  font-size: 2rem;

  @include breakpoint-max(ml) {
    font-size: 1.688rem;
  }
}

.heading-5 {
  @include font-500-24;

  color: $white-1;

  &.privacy {
    color: $purple-700;
  }
}

.anchor-offset {
  z-index: -100;
  margin-top: -7.5rem;
  position: absolute;
}

.cover {
  background-color: $purple-900;
  z-index: 10000;
  position: absolute;
  inset: 0%;
  transition: opacity 1s;
}

.aos-animate.aos-init {
  animation-name: fade-up;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
