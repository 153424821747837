// -------------------------------------
// Custom variables
// -------------------------------------

// Section
$section-max-width: rem(1128px);

// Header
$header-wrapper-width: rem(190px);
$header-logo-height: rem(42.6px);
$header-logo-mobile-width: 11.214rem;
$header-logo-mobile-height: 2.579rem;
$header-items-padding: rem(9.6px) rem(24px) rem(8.8px);

// controls
$border-radius-control: rem(6px);
$icon-size-control: rem(24px);
$control-height: rem(30px);

// border radius
$border-radius-0: rem(2px);
$border-radius-1: rem(4px);
$border-radius-2: rem(8px);
$border-radius-3: rem(16px);

// scrollbar
$scrollbar-horizontal-height: 10px;
$scrollbar-vertical-width: 6px;

// button
$border-radius-button: rem(8px);
$header-items-padding: rem(9.6px) rem(24px) rem(8.8px);

// select
$select-sm-height: rem(40px);
$select-mobile-sm-height: rem(40px);
$select-mobile-sm-width: rem(40px);
$select-height: rem(32px);
$select-arrow-size: rem(16px);

// pages
$pages-content-tablet-plus-width: rem(520px);
$pages-content-mobile-width: 100%;
$pages-content-mobile-margin: 200px;

// footer
$footer-height: rem(168px);
$footer-mobile-height: rem(258px);

// schemas
$schemas-max-width: rem(1280px);

// sign-in form
$form-width: rem(520px);
$toast-width: 332px;
$toast-mobile-width: 300px;

// Social Icons
$social-icons-container-height: rem(50px);
$social-icons-container-mobile-height: rem(100px);

// Main Section
$main-section-title-desktop-width-en: rem(420px);
$main-section-title-desktop-width-ru: rem(532px);
$main-section-sub-title-desktop-width: rem(486px);

// Language Selector
$language-selector-width: rem(73.45px);
$language-selector-height: rem(48px);
$language-selector-dropdown-height: rem(95px);
